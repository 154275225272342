import { ChatAlt2Icon } from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useServerTable } from "components/hooks/useServerTable";
import Slider from "components/layout/Slider";
import TableLayout from "components/layout/TableLayout";
import TableDescription from "components/tables/TableDescription";
import { WidgetProductsTable } from "components/tables/WidgetProductsTable";
import Link from "next/link";
import { useRouter } from "next/router";
import { LAYOUT } from "util/common";

export async function getServerSideProps(context) {
	const { req } = context;

	const meta = {
		title: "מוצרי רפואי | ג'יין - מלאי רפואי, השוואת מחירים, והזמנת אונליין",
		description:
			"רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי  הרפואי שזמינים כרגע לרכישה בבתי המרקחת ברחבי הארץ. ניתן לסנן ולמיין את הטבלה לפי סוג מוצר, מחיר, קטגוריית מינון, אפיון אינדיקה / סאטיבה, שיטת גידול, THC, CBD, ארץ ייצור, ועוד.",
		url: req.url,
	};

	return {
		props: {
			meta: meta,
		},
	};
}

export default function AllProducts({ prependContent }) {
	const router = useRouter();
	const isInIframe = useIsInIframe();
	const isWidget = router.pathname.includes("widget");

	const params = {
		requestPath: `widget/products/all/`,
		infiniteScrolling: true,
		pageSize: 25,
		layoutOptions: [LAYOUT.LIST, LAYOUT.GRID],
	};

	const tbl = useServerTable(params);
	const { title } = router.query;
	const header = "מוצרים";
	const breadcrumbs = [
		{ path: "/products/", text: "ראשי" },
		{ path: "/products/", text: "מוצרים" },
	];

	return (
		<>
			{!isInIframe && !isWidget && <Slider />}

			<div className="bg-gradient-to-l from-jane-200 to-white mt-8 text-jane-800 w-auto max-w-xl mx-auto flex flex-col-reverse sm:flex-row items-center p-6 mb-8 rounded-xl shadow-lg text-right border-r-4 border-jane-500 relative overflow-hidden">
				<div className="absolute -top-10 -left-10 w-24 h-24 bg-jane-500/10 rounded-full blur-xl"></div>
				<div className="absolute -bottom-10 -right-10 w-32 h-32 bg-jane-500/30 rounded-full blur-xl"></div>
				<div className="z-10 w-full">
					<div className="flex flex-col sm:flex-row gap-2 items-center w-full mb-3">
						<span className="bg-gradient-to-l from-red-600 to-red-500 text-white font-bold rounded-full px-4 py-1 text-sm shadow-md">
							חדש!
						</span>
						<h3 className="font-bold text-2xl">
							<ChatAlt2Icon className="inline-block w-7 h-7 sm:hidden ml-2" />
							עמוד ביקורות ופרסומים
						</h3>
					</div>
					<p className="mb-4 text-jane-700 text-center sm:text-right [font-size:clamp(0.875rem,3vw,1rem)] whitespace-nowrap sm:whitespace-normal overflow-hidden text-ellipsis">
						הכירו את דעת הקהל – קראו, הגיבו, והצטרפו לדיון.
					</p>
					<Link href="/feed">
						<a className="transition-all w-full sm:w-auto hover:scale-105">
							<Button
								primary
								className="px-5 py-2 rounded-lg w-full sm:w-auto shadow-md font-semibold !text-base"
							>
								לחצו כאן
							</Button>
						</a>
					</Link>
				</div>
				<ChatAlt2Icon className="hidden sm:block w-24 h-24 text-jane-500 mb-4 sm:mb-0 sm:ml-8" />
			</div>

			<TableLayout header={header} breadcrumbs={breadcrumbs}>
				<TableDescription pageName="products" />
				<WidgetProductsTable
					tbl={tbl}
					title={title || "מוצרים"}
					prependContent={prependContent}
					showPriceRange={true}
					showAds={true}
					isMainTable
				/>
			</TableLayout>
		</>
	);
}
