import React from "react";

function TableDescription({ pageName, dynamicValue = undefined }) {
	const descriptions = {
		products: (
			<p>
				כל מוצרי הקנאביס הרפואי הזמינים לרכישה כרגע <b>בכל בתי המרקחת</b> בארץ.
			</p>
		),
		singleProduct: dynamicValue !== undefined &&
			!dynamicValue.includes(undefined) && (
				<p>
					רשימת בתי המרקחת בהם המוצר <b>{dynamicValue}</b> זמין כעת לרכישה:
				</p>
			),
		singleManufacturer: dynamicValue !== undefined &&
			!dynamicValue.includes(undefined) && (
				<p>
					כל מוצרי הקנאביס הרפואי המיוצרים על ידי <b>{dynamicValue}</b>:
				</p>
			),
		singleMarketer: dynamicValue !== undefined &&
			!dynamicValue.includes(undefined) && (
				<p>
					כל מוצרי הקנאביס הרפואי המשווקים על ידי <b>{dynamicValue}</b>:
				</p>
			),
		desiredCart: (
			<p>
				כאן ניתן לבנות סל של כמה מוצרים ולהשוות את המחיר של כולם יחד בין בתי
				מרקחת שונים ברחבי הארץ
			</p>
		),
		productsByPrice: (
			<p>כל מוצרי הקנאביס הרפואי בכל בתי המרקחת, לפי מחיר (מהנמוך לגבוה).</p>
		),
		productsByTHC: (
			<p>
				כל מוצרי הקנאביס הרפואי בכל בתי המרקחת, לפי ריכוז THC (מהגבוה לנמוך):
			</p>
		),
		balancedProducts: (
			<p>כל מוצרי הקנאביס הרפואי בעלי יחס מאוזן של THC ו-CBD</p>
		),
		productsByCBD: (
			<p>
				כל מוצרי הקנאביס הרפואי בכל בתי המרקחת לפי ריכוז CBD (מהגבוה לנמוך):
			</p>
		),
		batches: <p>כל אצוות מוצרי הקנאביס הרפואי בכל בתי המרקחת:</p>,
		reviews: <p>כל הביקורות על מוצרי הקנאביס הרפואי בכל בתי המרקחת:</p>,
		newestProducts: (
			<p>מוצרי קנאביס רפואי חדשים שנכנסו לאחרונה למלאי בתי המרקחת בארץ:</p>
		),
		comingSoon: (
			<p>מוצרי קנאביס רפואי הצפויים להיכנס בקרוב למלאי בתי המרקחת בארץ:</p>
		),
		strains: (
			<p>
				גנטיקות (זני מקור) - לחצו על הגנטיקה כדי לצפות במוצרים מהשושלת הגנטית
				הרצויה:
			</p>
		),
		singleStrain: <p>שמות נרדפים (AKA): {dynamicValue}</p>,
		categories: (
			<p>מינונים - לחצו על המינון כדי לצפות במוצרים מהמינון הרצוי:</p>
		),
		stores: <p>רשימת בתי מרקחת המוכרים קנאביס רפואי בארץ:</p>,
		cities: (
			<p>
				מלאי קנאביס רפואי בכל בתי המרקחת בעיר - בחרו את שם העיר או הישוב וצפו
				ברשימת בתי המרקחת הזמינים בהם:
			</p>
		),
		sales: (
			<p>
				מבצעים והטבות על קניית קנאביס רפואי בבתי המרקחת. לחצו על שם בית מרקחת
				כדי לצפות במבצעים שהוא מציע:
			</p>
		),
		discountStores: (
			<p>
				בתי המרקחת המשתלמים ביותר בממוצע - מסודרים לפי ההנחה הממוצעת שהם מציעים
				על מוצרים:
			</p>
		),
		discountProducts: (
			<p>
				מוצרי קנאביס בהנחה, מסודרים לפי אחוז הנחה מהגבוה לנמוך (לחצו על שם המוצר
				כדי לגלות היכן הוא זמין במחיר זה):
			</p>
		),
		manufacturers: (
			<p>
				רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה
				כרגע לפי החברה המגדלת. לחצו על שם המגדל כדי לצפות ברשימת המלאי של כל
				מוצרי הקנאביס הזמינים שלו.
			</p>
		),
		marketers: (
			<p>
				רשימת מלאי מתעדכנת בזמן אמת של כל מוצרי הקנאביס הרפואי שזמינים לרכישה
				כרגע לפי החברה המשווקת. לחצו על שם המשווק כדי לצפות ברשימת המלאי של כל
				מוצרי הקנאביס הזמינים שלו.
			</p>
		),
		productsByMostSold: <p>רשימת המוצרים שנמכרו הכי הרבה:</p>,
		productType: (
			<p>
				כל מוצרי הקנאביס הרפואי מסוג {dynamicValue} הזמינים לרכישה כרגע בכל בתי
				המרקחת בארץ.
			</p>
		),
		growType: (
			<p>
				כל מוצרי הקנאביס הרפואי בגידול {dynamicValue} הזמינים לרכישה כרגע בכל
				בתי המרקחת בארץ.
			</p>
		),
		mostViewed: <p>המוצרים הפופולריים ביותר באתר ב-יום/שבוע האחרונים</p>,
	};
	const content = descriptions[pageName];

	return <div className="mb-1">{content}</div>;
}

export default TableDescription;
